$(document).ready(function () {
  new WOW().init();

  const overlay = $(`.overlay`);
  const btnTop = $(`.btn__top`);
  const modalTitle = $(`.modal__why-title`);
  const modalText = $(`.modal__why-description`);
  const modalWhy = $(`.modal__why`);
  const planBtn = $(`.btn__plan`);
  const navLink = $(`.nav__menu-link`);
  const headerAction = $(`.header__action`);
  const header = $(`.header`);
  const toggleBtn = $(`.header__toggle-btn`);

  const openProp = function () {
    $(`.preloader`).addClass(`hidden`);
  };
  setTimeout(openProp, 3000);

  const moveEl = function () {
    $(
      `.promo__animation .promo__animation1, .promo__animation .promo__animation2, .promo__animation .promo__animation3, .promo__animation .promo__animation4, .bottom-text__content`
    ).addClass(`start`);
  };
  setTimeout(moveEl, 3000);

  const handleScroll = function () {
    const scrollPosition = $(window).scrollTop();
    const windowWidth = $(window).width();

    if (scrollPosition > 200) {
      header.addClass(`is-fixed`);
    } else {
      header.removeClass(`is-fixed`);
    }

    if (windowWidth > 767) {
      if (scrollPosition > 1500) {
        btnTop.addClass(`is-visible`);
      } else {
        btnTop.removeClass(`is-visible`);
      }
    } else {
      if (scrollPosition > 1200) {
        btnTop.addClass(`is-visible`);
      } else {
        btnTop.removeClass(`is-visible`);
      }
    }
  };

  $(window).scroll(handleScroll);

  btnTop.on(`click`, function () {
    $(`html, body`).animate({ scrollTop: 0 }, 800);
    return false;
  });

  toggleBtn.on(`click`, function () {
    const self = $(this);
    $(self).toggleClass(`is-active`);
    headerAction.toggleClass(`is-open`);
    $(`html`).toggleClass(`no-scroll`);
  });

  navLink.add(planBtn).on(`click`, function () {
    const el = $(this).attr(`href`);
    headerAction.removeClass(`is-open`);
    toggleBtn.removeClass(`is-active`);
    $(`html`).removeClass(`no-scroll`);
    $(`html, body`).animate({ scrollTop: $(el).offset().top - 79 }, 1000);
    return false;
  });

  const openModalWhy = function () {
    overlay.addClass(`is-open`);
    $(`html`).addClass(`no-scroll`);
    modalWhy.addClass(`is-open`);
  };

  const closeModalWhy = function () {
    overlay.removeClass(`is-open`);
    $(`html`).removeClass(`no-scroll`);
    modalWhy.removeClass(`is-open`);
  };

  $(`.why__item`).on(`click`, `a`, function (event) {
    event.preventDefault();
    const $this = $(this);
    const txtTitle = $this
      .prev(`.why__item-text-content`)
      .children(`.why__item-text-title`)
      .text();
    const txtText = $this
      .prev(`.why__item-text-content`)
      .children(`.why__item-text-description`)
      .text();
    const modalImgSrc = $this
      .closest(`.why__item`)
      .children(`.why__item-img`)
      .children(`img`)
      .attr(`src`);

    modalTitle.text(txtTitle);
    modalText.text(txtText);
    $(`.modal__why-img img`).attr(`src`, modalImgSrc);

    openModalWhy();
  });

  $(`.modal__why .modal__close`).on(`click`, closeModalWhy);
});
